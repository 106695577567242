import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BuildLog = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Build Log" />
      
      <h1>Build Log</h1>

      <h2>Deploying</h2>

      <ul>
        <li>1. npm run build</li>
        <li>2. Delete everything in devfreddy.github.io repository</li>
        <li>3. Copy everything from /public to devfreddy.github.io</li>
        <li>4. Commit and push changes</li>
        <li>TO DO - Everything uses GH PAT's instead of deploy keys which is less secure. Build a Github action that uses a deploy key to do this.</li>
      </ul>

      <h2>Resources</h2>
      <ul>
        <li>Site Icon - https://thenounproject.com/search/?q=waffle&amp;i=163998</li>
        <li>https://emotion.sh/docs/introduction</li>
      </ul>
    </Layout>
  )
}

export default BuildLog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`